import React from 'react'

import { BrandReportingPage } from '../../components'

class MerkenRaportage extends React.Component {
  render() {
    return <BrandReportingPage />
  }
}

export default MerkenRaportage
